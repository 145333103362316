import React from 'react'
import { Layout, Contact, Seo } from '../components'

export default function FAQ() {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <Contact />
    </Layout>
  )
}
